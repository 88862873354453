const env = `${process.env.REACT_APP_ENV}`

const configs = {
    dev: {
        firebase: {
            apiKey: 'AIzaSyBGTndxghv8aO0pXaNkEy7vomVFwxnsVWA',
            authDomain: 'monash-wfh-dev.firebaseapp.com',
            databaseURL: 'https://monash-wfh-dev.firebaseio.com',
            projectId: 'monash-wfh-dev',
            storageBucket: 'monash-wfh-dev.appspot.com',
            messagingSenderId: '290874156503',
        },
        analysis: {
            measurementID: 'UA-164332548-2'
        }
    },
    uat: {
        firebase: {
            apiKey: 'AIzaSyDKCpckR0z-8LAJpGG2u4WHHl9BBm3ZSF8',
            authDomain: 'monash-wfh-uat.firebaseapp.com',
            databaseURL: 'https://monash-wfh-uat.firebaseio.com',
            projectId: 'monash-wfh-uat',
            storageBucket: 'monash-wfh-uat.appspot.com',
            messagingSenderId: '496500410471',
        },
        analysis: {
            measurementID: 'UA-164332548-3'
        }
    },
    prod: {
        firebase: {
            apiKey: 'AIzaSyAwaScl3JH5Tc8pqRy2cW8fXWlPrPfKneY',
            authDomain: 'monash-wfh-prod.firebaseapp.com',
            databaseURL: 'https://monash-wfh-prod.firebaseio.com',
            projectId: 'monash-wfh-prod',
            storageBucket: 'monash-wfh-prod.appspot.com',
            messagingSenderId: '262628271837',
        },
        analysis: {
            measurementID: 'UA-164332548-1'
        }
    },
}

const config = configs[!env || env === 'undefined' ? 'dev' : env]
const firebaseConfig = config.firebase
const gtmId = config.analysis.measurementID

export default config
export { firebaseConfig, gtmId }