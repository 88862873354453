import React from 'react'
import Page from './Page'

const PageKeyContacts = () => {
  // render

  return (
    <Page title='Key Contacts'>
      <h2>Key Contact & Resources</h2>

      <p><strong>
        <a
          href='https://www.monash.edu/esolutions'
          target='_blank'
          rel='noopener noreferrer'>
          eSolutions
        </a>
        {' '}03 9905 1777 or{' '}
        <a
          href='mailto:servicedesk@monash.edu'
          target='_blank'
          rel='noopener noreferrer'>
          servicedesk@monash.edu
        </a>
      </strong></p>
      <p>
        If you need help with anything IT at Monash, the Service Desk is your 
        first point of contact.
      </p>
      <p>
        Mon to Fri: 8am – 6pm
      </p>

      <br />

      <p><strong>
        Access HR (03) 990 20400 or{' '}
        <a
          href='mailto:hr@monash.edu'
          target='_blank'
          rel='noopener noreferrer'>
          hr@monash.edu
        </a>
      </strong></p>
      <p>
        or{' '}
        <a
          href='https://connect.monash.edu/askmonash/s/'
          target='_blank'
          rel='noopener noreferrer'>
          Ask Monash
        </a> 
        {' '}your questions.
      </p>
      <p>
        Open Mon–Fri, 9am - 5pm
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.monash.edu/policy-bank/workplace-policy/covid-19'
          target='_blank'
          rel='noopener noreferrer'>
          COVID-19 HR Resources
        </a>
      </strong></p>
      <p>
      Monash has developed these guidelines to support our staff during this 
      challenging and evolving time. Additionally, links and resources have been 
      provided should you need additional support.
      </p>

      <br />

      <p><strong>
         <a
          href='https://benestar.com/user/login?current=/node/14'
          target='_blank'
          rel='noopener noreferrer'>
          EAP
        </a> 
        {' '}1300 360 364
      </strong></p>
      <p>
        <a
            href='https://www.intranet.monash/hr/home/featured-items/row-2/featured-jobs/employee-assistance-program'
            target='_blank'
            rel='noopener noreferrer'>
            Employee Assistance Program
        </a>  
        {' '}(EAP) is provided by Benestar. This is a professional support service available to all Monash 
        employees and families. Support is available via face-to-face, telephone, online, 
        or via LiveChat on the health and wellbeing portal Benehub.
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.monash.edu/safer-community'
          target='_blank'
          rel='noopener noreferrer'>
          Monash Safer Communities
        </a>
        {' '}03 9905 1599 or{' '}
        <a
          href='mailto:safercommunity@monash.edu'
          target='_blank'
          rel='noopener noreferrer'>
          safercommunity@monash.edu
        </a>
      </strong></p>
      <p>
        The Safer Community Unit is focused on early identification of observable behaviour 
        that presents a risk to the safety and wellbeing of self or others 
        (
        <a
          href='https://www.monash.edu/safer-community/problem-behaviour/family-and-interpersonal-violence'
          target='_blank'
          rel='noopener noreferrer'>
          including family and interpersonal violence
        </a>
        ) 
      </p>
      <p>
        Open Mon–Fri, 9am – 5pm
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.beyondblue.org.au/'
          target='_blank'
          rel='noopener noreferrer'>
          Beyond Blue
        </a>
        {' '}1300 22 4636
      </strong></p>
      <p>
        Beyond Blue provides information and support to help everyone in Australia 
        achieve their best possible mental health. 
      </p>
      <p>
        Available 24 hours
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.lifeline.org.au/'
          target='_blank'
          rel='noopener noreferrer'>
          Lifeline
        </a>
        {' '}13 11 14
      </strong></p>
      <p>
        Lifeline is a national charity providing all Australians experiencing a 
        personal crisis with access to 24 hour crisis support and suicide prevention services 
      </p>
      <p>
        Available 24 hours
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.1800respect.org.au/'
          target='_blank'
          rel='noopener noreferrer'>
          1800RESPECT
        </a>
        {' '}1800 737 732
      </strong></p>
      <p>
        National sexual assault, domestic family violence counselling service. Open 24 hours 
        to support people impacted by sexual assault, domestic or family violence and abuse.
      </p>
      <p>
        Available 24 hours
      </p>

      <br />

      <p><strong>
        <a
          href='https://mensline.org.au/'
          target='_blank'
          rel='noopener noreferrer'>
          Mensline Australia
        </a>
        {' '}1300 78 99 78
      </strong></p>
      <p>
        MensLine Australia is a telephone and online counselling service for men with 
        emotional health and relationship concerns. We’re here to help anywhere, anytime.
      </p>
      <p>
        Available 24 hours
      </p>

      <br />

      <p><strong>
        <a
          href='https://kidshelpline.com.au/'
          target='_blank'
          rel='noopener noreferrer'>
          Kids Helpline
        </a>
        {' '}1800 55 1800
      </strong></p>
      <p>
        Kids Helpline is an Australian free, private and confidential, telephone 
        and online counselling service specifically for young people aged between 
        5 and 25. Anytime. Any Reason.
      </p>
      <p>
        Available 24 hours
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.safesteps.org.au'
          target='_blank'
          rel='noopener noreferrer'>
          Safe Steps
        </a>
        {' '}1800 015 188
      </strong></p>
      <p>
        Supporting women and children in Victoria to live free from family violence.
      </p>

      <br />

      <p><strong>
        <a
          href='https://djirra.org.au/'
          target='_blank'
          rel='noopener noreferrer'>
          Djirra
        </a>
        {' '}1800 105 303
      </strong></p>
      <p>
        Supporting the safety and wellbeing of Aboriginal women experiencing family violence.
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.education.vic.gov.au/parents/services-for-parents/Pages/parentline.aspx'
          target='_blank'
          rel='noopener noreferrer'>
          Parentline
        </a>
        {' '}13 22 89
      </strong></p>
      <p>
        Phone service for parents and carers of children from birth to 18 years old 
        offering confidential and anonymous counselling and support on parenting issues
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.panda.org.au/info-support'
          target='_blank'
          rel='noopener noreferrer'>
          PANDA
        </a>
        {' '}1300 726 306
      </strong></p>
      <p>
        National support for individuals and families to recover from perinatal 
        anxiety and depression
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.sane.org'
          target='_blank'
          rel='noopener noreferrer'>
          SANE
        </a>
        {' '}1800 187 263
      </strong></p>
      <p>
        Support for Australians affected by complex mental health issues
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.relationships.org.au'
          target='_blank'
          rel='noopener noreferrer'>
          Relationships Australia
        </a>
        {' '}1300 264 277
      </strong></p>
      <p>
        National support for relationships for abusive and abused partners
      </p>

      <br />

      <p><strong>
        <a
          href='https://headspace.org.au/'
          target='_blank'
          rel='noopener noreferrer'>
          Headspace
        </a>
        {' '}
      </strong></p>
      <p>
        National mental health support service for young people under the age of 25 
      </p>

      <br />

      <p><strong>
        <a
          href='https://intouch.org.au'
          target='_blank'
          rel='noopener noreferrer'>
          In Touch
        </a>
        {' '}1800 755 988
      </strong></p>
      <p>
        Multicultural centre supporting the needs of refugee and migrant women 
        experiencing family voilence 
      </p>

      <br />

      <p><strong>
        <a
          href='https://www.ntv.org.au'
          target='_blank'
          rel='noopener noreferrer'>
          No to violence
        </a>
        {' '}1300 766 491
      </strong></p>
      <p>
        Working with men to end family violence in Victoria and New South Wales
      </p>

    </Page>
  )
}

export default PageKeyContacts
