import React from 'react'
import Page from './Page'

const Workplace = () => {
  // render

  return (
    <Page title="We'd love to hear from you">
      <h2>Workplace group</h2>
      <p>
        This workplace group is for the Monash community to connect and share during 
        our time working from home.
      </p>
      <p>
        We'll share articles, tips and resources for surviving work at home and we 
        encourage you to share also. Have you found an article on best practices for 
        working from home? Have you found a great workout video? Using a fabulous 
        mindfulness app? We want to hear about it!
      </p>
      <p>
        Let's stay connected, share our knowledge and build our @ home community. 
        Let's work better together...
      </p>
      <p>
        <a
          href='https://monash.workplace.com/groups/256058485554571/'
          target='_blank'
          rel='noopener noreferrer'>
          We'd love to hear from you!
        </a>
      </p>

    </Page>
  )
}

export default Workplace
