import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import s from './content-header.module.scss'
import listOfTopics from 'components/main/topics/list-of-topics'
import Nav from 'components/header/nav/Nav'
import { useRouteMatch } from 'react-router-dom'
import { IconCross, IconMenu } from 'components/core/icons/Icons'

const ContentHeader = ({ title }) => {
  const path = useRouteMatch().path.replace(/\/topics\//g, '')
  const ref = useRef()
  const [fixed, setFixed] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  // bind scroll to detect when to fix header

  useEffect(() => {
    const checkPos = () => {
      window.pageYOffset > ref.current.offsetTop
        ? setFixed(true)
        : setFixed(false)
    }

    window.addEventListener('scroll', checkPos, { passive: true })

    return () => window.removeEventListener('scroll', checkPos)
  }, [])

  // list of topics

  const list = listOfTopics.map((item, i) => {
    const src = item.name
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/:/g, '')
      .replace(/&/g, 'and')

    return (
      <li key={i} className={path === src ? s.active : ''}>
        <Link to={`/topics/${src}`}>{item.name}</Link>
      </li>
    )
  })

  list.push(
    <li key={listOfTopics.length}>
      <a
        href='https://www.monash.edu/__data/assets/pdf_file/0005/2173559/staff-quick-reference-guide.pdf'
        target='_blank'
        rel='noopener noreferrer'>
        Quick Guide: Remote System Access (pdf)
      </a>
    </li>
  )

  // render

  return (
    <header className={s.contentHeader}>
      <Nav></Nav>

      <div ref={ref} className={s.pusher}></div>

      <div className={s.fixedWrapper}>
        <div className={`${s.fixedContainer} ${fixed ? s.fixed : ''}`}>
          <div className={s.container}>
            <div
              className={`${s.mobileMenuWrapper} ${menuOpen ? s.active : ''}`}>
              <div className={s.mobileMenuContainer}>
                <ul className={s.menu}>{list}</ul>
              </div>
            </div>
            <h1>{title}</h1>
          </div>
        </div>
      </div>

      <div
        className={s.floatingActionButton}
        onClick={(e) => setMenuOpen(!menuOpen)}>
        {menuOpen ? <IconCross></IconCross> : <IconMenu></IconMenu>}
      </div>
    </header>
  )
}

export default ContentHeader
