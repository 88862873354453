import React from 'react'
import s from './further-information.module.scss'
import Card from 'components/core/card/Card'
import { IconPDF, IconExternalLink } from 'components/core/icons/Icons'
import remoteAccessGuide from 'assets/pdf/remote-access-guide.pdf'
import essentialLinks from 'assets/pdf/staff-essential-links.pdf'

const FurtherInformation = () => {
  // render

  return (
    <div className={s.furtherInformation}>
      {/* header */}
      <header>
        <h2>Further information</h2>
        <span>
          Stay up-to-date via Workplace:{' '}
          <a
            href='https://www.google.com/url?q=https%3A%2F%2Fmonash.workplace.com%2Fgroups%2Fmonashannouncements%2F&sa=D&sntz=1&usg=AFQjCNERWCVPR9XoqYpm3GwnFUS6bKVZrw'
            target='_blank'
            rel='noopener noreferrer'>
            Monash University Announcements
          </a>{' '}
          &amp;{' '}
          <a
            href='https://www.google.com/url?q=https%3A%2F%2Fmonash.workplace.com%2Fgroups%2F880040492174165%2F&sa=D&sntz=1&usg=AFQjCNEOm4h5zEB685uMM7_cYdzFc5rMYw'
            rel='noopener noreferrer'
            target='_blank'>
            Everyone at Monash
          </a>
          . Keep up to date via the
          <a
            href='https://www.monash.edu/news/coronavirus-updates'
            target='_blank'
            rel='noopener noreferrer'>
            {' '}
            COVID-19 Page
          </a>
        </span>
      </header>

      {/* contact info */}

      <div className={s.contactInfoCard}>
        <Card>
          <p>
            For OHS matters, contact the helpline on{' '}
            <a href='tel:(03) 990 51016'>(03) 990 51016</a> or{' '}
            <a href='mailto:ohshelpline@monash.edu'>ohshelpline@monash.edu</a>
          </p>
          <p>
            If you have questions relating to this guide, contact Monash HR on{' '}
            <a href='tel:(03) 990 20400'>(03) 990 20400</a> or{' '}
            <a href='mailto:hr@monash.edu'>hr@monash.edu</a>
          </p>
          <p>
            The{' '}
            <a
              href='https://www.google.com/url?q=https%3A%2F%2Fwww.intranet.monash%2Fhr%2Fhome%2Ffeatured-items%2Frow-2%2Ffeatured-jobs%2Femployee-assistance-program&sa=D&sntz=1&usg=AFQjCNH25dcPvRMZGWGqnUcnB2-j5bQSQg'
              target='_blank'
              rel='noopener noreferrer'>
              Employee Assistance Program
            </a>{' '}
            (EAP) is a professional support service available to all employees,
            confidential, short-term support for a variety of work-related and
            personal problems.
          </p>
        </Card>
      </div>

      {/* other links */}

      <div className={s.otherLinks}>
        <Card>
          <ul>
            {/* remote access guide */}
            <li>
              <a
                href={remoteAccessGuide}
                target='_blank'
                rel='noopener noreferrer'>
                <h3 className={s.redIcon}>
                  <span>Staff Remote Access Guide</span>
                  <IconPDF></IconPDF>
                </h3>
                <p>
                  Information about (MFA), VPN, MoVE, Zoom, Telephone extension,
                  Mobility, CISCO Softphone, Install software on Monash
                  computer, Install other applications, Internet access via
                  Mobile Device
                </p>
              </a>
            </li>

            {/* essential links */}
            <li>
              <a
                href={essentialLinks}
                target='_blank'
                rel='noopener noreferrer'>
                <h3 className={s.redIcon}>
                  <span>Staff Remote Access essential links</span>
                  <IconPDF></IconPDF>
                </h3>
                <p>
                  Essential links about MFA, VPN Install software on Monash
                  devices, MoVE, Software, set-up-mobile-tethering-or-hotspot,
                  Zoom, Mobility, Softphone-FAQ, eSolutions
                </p>
              </a>
            </li>

            {/* internet */}
            <li>
              <a
                href='https://monash.edu/esolutions/systems/staff-remote-access'
                target='_blank'
                rel='noopener noreferrer'>
                <h3>
                  <span>Monash Internet (eSolutions)</span>
                  <IconExternalLink></IconExternalLink>
                </h3>
                <p>Access key University systems and tools.</p>
              </a>
            </li>

            {/* intranet */}
            <li>
              <a
                href='https://www.intranet.monash/esolutions'
                target='_blank'
                rel='noopener noreferrer'>
                <h3>
                  <span>Monash Intranet (eSolutions)</span>
                  <IconExternalLink></IconExternalLink>
                </h3>
                <p>Access the Monash Intranet</p>
              </a>
            </li>
          </ul>
        </Card>
      </div>
    </div>
  )
}

export default FurtherInformation
