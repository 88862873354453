import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'
import { firebaseConfig } from './config'


const useProxy = (db) => {

  firebase.database.INTERNAL.forceLongPolling() //force longpolling

  //get the host/base url ready
  let databaseUrl = 'localhost:8080/firebase/rtdb'
  let secure = false
  if (!window.location.origin.startsWith('http://localhost')) {
    databaseUrl = `${window.location.host}/firebase/rtdb`
    secure = true
  }

  db.repo_.repoInfo_.secure = secure
  
  //change the url firebase has set up to the gateway url
  db.repo_.repoInfo_.internalHost = databaseUrl
  db.repo_.repoInfo_.host = databaseUrl //not sure if this is needed?
  
  //override firebase sdk from subsequently changing any urls back
  db.repo_.repoInfo_.updateHost = (newHost) => {
      console.log('[Firebase RTDB] URL update prevented')
  }

  return db
}

const fb = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()

const db = useProxy(fb.database())
const auth = fb.auth() // @firebase/auth is patched.
const fn = fb.functions()
const store = fb.storage()

export default fb
export { db, auth, fn, store }
