function importAll(r) {
  let list = {}
  r.keys()
  for (let item of r.keys()) {
    list[item.replace('./', '').replace(/\.(png|jpe?g|gif|svg)$/, '')] = r(item)
  }
  return list
}

export default importAll
