import React from 'react'
import s from './nav.module.scss'
import { IconMonashLogo } from 'components/core/icons/Icons'
import { Link } from 'react-router-dom'

const Nav = () => {
  // render

  return (
    <nav className={s.nav}>
      <Link to='/' className={s.left}>
        <IconMonashLogo></IconMonashLogo>
        <span>Monash @ Home</span>
      </Link>

      <div className={s.right} />
    </nav>
  )
}

export default Nav
