import React, { useContext, useEffect } from 'react'
import './global.scss'
import Header from 'components/header/Header'
import Main from 'components/main/Main'
import Footer from 'components/footer/Footer'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PageGettingReady from 'components/pages/PageGettingReady'
import PageSettingYourselfUpForSuccess from 'components/pages/PageSettingYourselfUpForSuccess'
import DigitalLiteracy from 'components/pages/PageDigitalLiteracy'
import PageManagingRemoteTeams from 'components/pages/PageManagingRemoteTeams'
import PageRemainingEffectiveAndEfficient from 'components/pages/PageRemainingEffectiveAndEfficient'
import PageStayingWell from 'components/pages/PageStayingWell'
import PageWorkingFromHomeAndCaregiving from 'components/pages/PageWorkingFromHomeAndCaregiving'
import PageCovid19Updates from 'components/pages/PageCovid19Updates'
import PageMicrolearningHotTopics from 'components/pages/PageMicrolearningHotTopics'
import PageStayingConnected from 'components/pages/PageStayingConnected'
import PageKeyContacts from './components/pages/PageKeyContacts'
import PageWorkplace from './components/pages/PageWorkplace'
import PageBackOnCampus from './components/pages/PageBackOnCampus'

import { AuthContext } from './utils/AuthContext'

const App = () => {

  const auth = useContext(AuthContext)

  useEffect(() => {
    if (!auth.loggedIn) {
      auth.login()
    }
  }, [auth]);

  if (!auth.loggedIn) return []
  console.log("Login result: ", auth)
  
  return (
    <div className='app'>
      <Router>
        <Switch>
          {/* topics */}
          <Route path='/topics/getting-ready'>
            <PageGettingReady></PageGettingReady>
          </Route>

          <Route path='/topics/back-on-campus'>
            <PageBackOnCampus></PageBackOnCampus>
          </Route>

          <Route path='/topics/setting-yourself-up-for-success'>
            <PageSettingYourselfUpForSuccess></PageSettingYourselfUpForSuccess>
          </Route>

          <Route path='/topics/digital-literacy'>
            <DigitalLiteracy></DigitalLiteracy>
          </Route>

          <Route path='/topics/staying-connected'>
            <PageStayingConnected></PageStayingConnected>
          </Route>

          <Route path='/topics/managing-remote-teams'>
            <PageManagingRemoteTeams></PageManagingRemoteTeams>
          </Route>

          <Route path='/topics/remaining-effective-and-efficient'>
            <PageRemainingEffectiveAndEfficient></PageRemainingEffectiveAndEfficient>
          </Route>

          <Route path='/topics/staying-well'>
            <PageStayingWell></PageStayingWell>
          </Route>

          <Route path='/topics/working-from-home-and-caregiving'>
            <PageWorkingFromHomeAndCaregiving></PageWorkingFromHomeAndCaregiving>
          </Route>

          <Route path='/topics/covid-19-updates'>
            <PageCovid19Updates></PageCovid19Updates>
          </Route>

          <Route path='/topics/micro-learning-hot-topics'>
            <PageMicrolearningHotTopics></PageMicrolearningHotTopics>
          </Route>

          <Route path='/topics/key-contacts'>
            <PageKeyContacts></PageKeyContacts>
          </Route>

          <Route path="/topics/we'd-love-to-hear-from-you">
            <PageWorkplace></PageWorkplace>
          </Route>

          {/* home */}
          <Route path='/'>
            <Header></Header>
            <Main></Main>
          </Route>
        </Switch>
      </Router>
      <Footer></Footer>
    </div>
  )
}

export default App
