import React from 'react'
import Page from './Page'

const PageManagingRemoteTeams = () => {
  // render

  return (
    <Page title='Managing remote teams'>
      <blockquote>
        Great things in business are never done by one person; they're done by a team of people.
        <aside>
        Steve Jobs
        </aside>
      </blockquote>
      <h2>Preparation</h2>
      <p>
      Managing well, in person or from a distance requires the same skill set. 
      You will need to coach, give timely and actionable feedback, set priorities, 
      clarify expectations, protect your team's time and use 1's on 1's to increase 
      engagement, confidence and support
      </p>

      <p>
        <b>
          Before you and your team start working from home, consider the
          following:
        </b>
      </p>
      <ul>
        <li>
          What day / time will you hold your weekly team meetings and 1's on 1's? 
          Keep in mind working hours of each team member and those with family responsibilities
        </li>
        <li>
          How regularly (aside from the weekly meeting) will you 'touch base /
          virtual check in' throughout the week?
        </li>
        <li>
          What is the best way to contact each team member? Zoom? Hangouts? the plain old phone...
        </li>
        <li>
          What will your team be working on while they're at home? If their normal work 
          has been disrupted, are there projects/work that has been the team's wish list?
        </li>
        <li>What hours of work have been agreed upon by you and your team members?</li>
        <li>Does your team have the resources needed to work from home?</li>
      </ul>

      <p>
        <b>Ask:</b> “What can I do to support you through this transition?”
      </p>
      <p>
        <b>Remember:</b> Each staff member will respond to this change
        differently and your staff may show a range of emotions; fear, anxiety,
        frustration, which are important to acknowledge.
      </p>
      <p>
        All Monash staff have free access our{' '}
        <a
          href='https://www.google.com/url?q=https%3A%2F%2Fwww.intranet.monash%2Fhr%2Fhome%2Ffeatured-items%2Frow-2%2Ffeatured-jobs%2Femployee-assistance-program&sa=D&sntz=1&usg=AFQjCNH25dcPvRMZGWGqnUcnB2-j5bQSQg'
          target='_blank'
          rel='noopener noreferrer'>
          Employee Assistant Program
        </a>{' '}
        which is a confidential, short-term support for a variety of
        work-related and personal problems that may be affecting staff at work
        or at home.
      </p>
      <p>
      Benestar’s team of clinicians are here to help your employees through this time, and we encourage you to share our MyCoach service leaflets with your employees.
        <ul>
          <li>
            <a
              href='https://s3.ap-southeast-2.amazonaws.com/assets.benestar.com/s3fs-public/2020-03/Benestar%20MyCoach%20for%20Individuals%20COVID-19.pdf?qb7QitTY_3RldLqo0WyB1UAYkPOnqKe1'
              target='_blank'
              rel='noopener noreferrer'>
              MyCoach for Individuals
            </a>
          </li>
          <li>
            <a
              href='https://s3.ap-southeast-2.amazonaws.com/assets.benestar.com/s3fs-public/2018-12/BNS110%20MyCoach%20for%20People%20Leaders_v3.pdf'
              target='_blank'
              rel='noopener noreferrer'>
              MyCoach for People Leaders
            </a>
          </li>
          <li>
            <a
              href='https://s3.ap-southeast-2.amazonaws.com/assets.benestar.com/s3fs-public/2019-06/Benestar%20MyCoach%20for%20Money%20Flyer_Digital.pdf?bLfjPHVKHsq7YLjckBMBdYznpLRm42_D'
              target='_blank'
              rel='noopener noreferrer'>
              MyCoach for Money
            </a>
          </li>
          <li>
            <a
              href='https://s3.ap-southeast-2.amazonaws.com/assets.benestar.com/s3fs-public/2018-12/Benestar%20MyCoach%20for%20Nutrition%20Fact%20Sheet.pdf'
              target='_blank'
              rel='noopener noreferrer'>
              MyCoach for Nutrition
            </a>
          </li>
        </ul>
      </p>

      <h2>Learn the nuances of managing a virtual team</h2>
      <p>
        <a
          href='https://www.google.com/url?q=https%3A%2F%2Fmonash.csod.com%2Fui%2Flms-learning-details%2Fapp%2Fcourse%2Ffc69cf06-f9aa-5ea7-8f3f-c8d1844d8d59&sa=D&sntz=1&usg=AFQjCNEzL4vS5GW3WGiLSFAfycETUGH7fQ'
          target='_blank'
          rel='noopener noreferrer'>
          Managing Virtual Teams
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>Learn how to:</p>
      <ul>
        <li>Ensure productivity & engagement</li>
        <li>Build trust</li>
        <li>Remove roadblocks</li>
        <li>Nurture connections with team members</li>
        <li>Set clear goals</li>
      </ul>

      <h2>Creating an Action Plan</h2>
      <p>
        <b>Weekly Planning</b>
      </p>
      <ul>
        <li>
          Use your calendar to schedule / invite your team members to meetings /
          virtual catch-up's.
        </li>
        <li>
          Keep the meetings! Don't cancel - people need to feel connected.
        </li>
        <li>
          Consider how you can best support the individual needs of each of your
          team members.
        </li>
        <li>
          Be open to regular video calls or/& dial in, letting your team know
          they can connect with you (or one another) for support.
        </li>
        <li>Provide the team with updates promptly.</li>
      </ul>
      <p>
        <b>Remember:</b> Email is a great tool for communicating, but shouldn't
        be the 'only' tool...
      </p>

      <h2>Learn how to hold a virtual team meeting:</h2>
      <p>
        <a
          href='https://hbr.org/2020/03/what-it-takes-to-run-a-great-virtual-meeting'
          target='_blank'
          rel='noopener noreferrer'>
          What It Takes to Run a Great Virtual Meeting
        </a>
        {' '}(Harvard Business Review Article)
      </p>
      <p>Below is a summary of the recommendations</p>
      <ul>
        <li>Use video where possible - however, always provide a dial-in option as a back up</li>
        <li>Ensure faces are visible</li>
        <li>Stick to meeting basics</li>
        <li>Assign one facilitator to ensure every voice is heard</li>
        <li>Encourage everyone to speak - meetings are a team effort</li>
      </ul>

      <h2>Online Learning</h2>
      <p>
        <b>Have you led a remote team before?</b>
      </p>
      <p>
        <a
          href='https://www.google.com/url?q=https%3A%2F%2Fmonash.csod.com%2Fui%2Flms-learning-details%2Fapp%2Fcourse%2Ffbb23f5e-d7b5-51ed-bfe2-be3e8d1cb19d&sa=D&sntz=1&usg=AFQjCNHeIKGqPcDphg5qYDFyFpgW2dVoGA'
          target='_blank'
          rel='noopener noreferrer'>
          Leading at a Distance
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Learn the remote leadership basics, how to get results, how to engage
        your team and understanding yourself as a remote leader.
      </p>

      <h2>How to Lead Through a Crisis</h2>
      <p>
        <a
          href='https://www.ccl.org/articles/leading-effectively-articles/how-to-lead-through-a-crisis/?utm_source=external-email&utm_medium=email&utm_campaign=apac_marketing_liy-newsletter-march_march192020%20(1)&utm_content=&spMailingID=64324650&spUserID=NDIyMjY5MTcxODA5S0&spJobID=1842194180&spReportId=MTg0MjE5NDE4MAS2'
          target='_blank'
          rel='noopener noreferrer'>
          5 Ways to Lead and Adapt Through a Crisis
        </a>
        {' '}(Center for Creative Leadership Blog)
      </p>
      <p>
        When faced with a crisis, most leaders are forced to think and behave in
        ways that feel unfamiliar. Whether it’s a technological, financial,
        natural, or health crisis - at work or in the community - crises demand
        that leaders take an emergency response plan and adapt it as new
        evidence and factors present themselves.
      </p>

      <p>
        <a
          href='https://hbr.org/2020/04/how-managers-can-support-remote-employees'
          target='_blank'
          rel='noopener noreferrer'>
          How Managers Can Support Remote Employees
        </a>
        {' '}(Harvard Business Review)
      </p>
      <p>
        In the global transition from corporate hallways to home offices, we’ve 
        left something behind: meaningful access to managers.
      </p>

      <h2>Online Module</h2>
      <p>
        <b>Learn how to hold a virtual team meeting</b>
      </p>
      <p>
        <a
          href='https://www.google.com/url?q=https%3A%2F%2Fmonash.csod.com%2Fsamldefault.aspx%3FReturnUrl%3D%25252fDeepLink%25252fProcessRedirect.aspx%25253fmodule%25253dlodetails%252526lo%25253d5abb7b84-cd07-4728-96ed-658e99391671&sa=D&sntz=1&usg=AFQjCNHEKQ05Sn5ARVOmFVRai9MRcBtPpw'
          target='_blank'
          rel='noopener noreferrer'>
          Leading Virtual meetings
        </a>
        {' '}(Online Module by DDI)
      </p>
      <p>
        Virtual meetings are becoming more common as leaders and employees work
        remotely. Technology makes it possible for teams to instantly meet,
        regardless of their location – but working remotely and attending
        virtual meetings present a new set of challenges in communicating,
        collaborating, and avoiding distractions to remain productive.
      </p>
    </Page>
  )
}

export default PageManagingRemoteTeams
