import React from 'react'
import Page from './Page'

const PageStayingWell = () => {
  // render

  return (
    <Page title='Staying well'>
      <blockquote>
        Nurturing yourself is not selfish... It is essential to our survival and
        your wellbeing
        <aside>Renee Trudeau</aside>
      </blockquote>
      <h2>A guide to staying well</h2>
      <p>
        Please see below tips and resources to support and foster your
        wellbeing.
      </p>
      <h3>Psychological Wellbeing</h3>
      <p>
        <a
          href='https://www.beyondblue.org.au/the-facts/looking-after-your-mental-health-during-the-coronavirus-outbreak'
          target='_blank'
          rel='noopener noreferrer'>
          Looking after your mental health during the coronavirus outbreak
        </a>{' '}
        (Beyond Blue Blog)
      </p>
      <p>
        Beyond Blue recognises and understands the feelings of anxiety, distress
        and concern many people may be experiencing in relation to the
        coronavirus (COVID-19). The below article offers great advice on
        supporting our wellbeing.
      </p>

      <p>
        <a
          href='https://www.worklogic.com.au/workplace-culture/keeping-up-morale-tips-to-support-an-at-home-workforce/'
          target='_blank'
          rel='noopener noreferrer'>
          Keeping up morale: Tips to support an at-home workforce
        </a>{' '}
        (Worklogic Blog)
      </p>
      <p>
        Worklogic offer some great tips for keeping our workforce engaged,
        connected and productive during this time.
      </p>

      <p>
        <a
          href='https://drive.google.com/file/d/1JLrouqE5o8ZQwQ9-7CPFNBsFl4OrYbux/view'
          target='_blank'
          rel='noopener noreferrer'>
          Beating Burnout and Loneliness in a World of Flexibility and Remote
          Work
        </a>{' '}
        (Gartner Blog)
      </p>
      <p>
        More and more people are feeling tired and lonely at work... to help
        build positive social relations, it is recommend that employers promote
        a culture of inclusion and empathy, encourage employees to build
        developmental networks, and celebrate group successes.
      </p>

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/8b4e161b-a549-5f88-b570-06d0a63cb61c'
          target='_blank'
          rel='noopener noreferrer'>
          Leveraging the Connection between Well-Being and Productivity
        </a>{' '}
        (LinkedIn Learning Module)
      </p>
      <p>
        Live a life filled with less stress, greater well-being, and enhanced
        productivity by learning how to make transformational changes.
      </p>
      
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/62d527f6-a8c8-52f0-af6d-ef80a2c1e62c'
          target='_blank'
          rel='noopener noreferrer'>
          Mindful Meditations for Work and Life
        </a>{' '}
        (LinkedIn Learning Module)
      </p>
      <p>
        A practical and easy approach to meditation, including simple, short
        exercises that you can build into a regular practice - even in the
        minutes before a meeting. Learn the science underlying the brain-body
        connection and how adapting your posture and changing your position can
        help you relax.
      </p>
      
      <p>
        <a
          href='https://www.futurelearn.com/courses/mindfulness-wellbeing-performance?utm_campaign=monash_university_mindfulness_wellbeing_performance_march_2020&utm_medium=organic_social&utm_source=FB_WP_MonashWB_KM'
          target='_blank'
          rel='noopener noreferrer'>
          Mindfulness for Wellbeing and Peak Performance
        </a>{' '}
        by Monash University, Professor Craig Hassad and Dr Richard Chambers.
        (Future Learn Online Module)
      </p>
      <p>
        Learn mindfulness techniques to reduce stress and improve your wellbeing
        and work/study performance in this online course.
      </p>

      <p>
        <a
          href='https://www.ted.com/talks/brene_brown_the_power_of_vulnerability'
          target='_blank'
          rel='noopener noreferrer'>
          Brene Brown - The Power of Vulnerability
        </a>{' '}
        (TEDTalk)
      </p>
      <p>
        In this TED talk, Brené Brown, who studies vulnerability, brings us into
        how we can live a more meaningful life In these challenging times
        through our ability to connect, empathize, and show courage.
      </p>

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/15002bc1-bff4-5585-bf5e-3e817db8e033'
          target='_blank'
          rel='noopener noreferrer'>
          Mindfulness Practices
        </a>{' '}
        (LinkedIn Learning Module)
      </p>
      <p>
        Mindfulness helps you be more effective in today’s busy world. It
        reduces stress, increases focus, and improves your ability to deal with
        challenges, both at work and at home.
      </p>
      
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/cf9c98cf-2291-544e-98d0-ecb48ec8f0cc'
          target='_blank'
          rel='noopener noreferrer'>
          Setting Priorities and Letting Go
        </a>{' '}
        (LinkedIn Learning Module)
      </p>
      <p>
        Pursue the next step in your Thrive journey and discover how to go from
        coping and surviving to actually thriving.
      </p>
      
      <p>
        <a
          href='https://www.intranet.monash/hr/home/featured-items/row-2/featured-jobs/employee-assistance-program'
          target='_blank'
          rel='noopener noreferrer'>
          Employee Assistance Program
        </a>
        (EAP) is provided by Benestar. This a professional support service available 
        to all Monash employees. You and your immediate family can access up to six 
        free counselling sessions.
      </p>

      <p>
        They are providing a{' '}
        <a
          href='https://vimeo.com/showcase/covid-19-webinars'
          target='_blank'
          rel='noopener noreferrer'>
          webinar support series
        </a>{' '}
        to share practical and evidence-based advice and information with
        organisations and their employees during these challenging times.
      </p>

      <p>
        <a
          href='https://www.psychologytoday.com/au/blog/the-mental-hygienist/202003/gratitude-in-time-pandemic'
          target='_blank'
          rel='noopener noreferrer'>
          Grattidue in a time of Pandemic
        </a>{' '}
      </p>
      <p>
          Gratitude can be a powerful tool for resilience in the face of COVID-19. 
      </p>

      <p>
        <a
          href='https://github.com/beisci/SleepInfo/blob/master/sleep_in_isolation.md'
          target='_blank'
          rel='noopener noreferrer'>
          Sleeping tips when staying indoors during isolation period
        </a>{' '}
      </p>
      <p>
        The Monash University Healthy Sleep Clinic have provided so some useful 
        tips on improving and prioritising sleep during isolation.  
      </p>

      <p>
        <a
          href='https://www.monash.edu/ohs/health-and-wellbeing/be-well-at-monash'
          target='_blank'
          rel='noopener noreferrer'>
          Be Well at Monash
        </a>{' '}
      </p>
      <p>
        Be Well at Monash provides a range of resources to support staff to maintain 
        good health and wellbeing while at home. These include programs focused on 
        Physical health, mental health and mindfulness and healthy eating. 
      </p>

      <h3>Physical Wellbeing</h3> 

      <p>
        <a
          href='https://www.monash.edu/sport/active-bodies-online'
          target='_blank'
          rel='noopener noreferrer'>
          Active Bodies Online
        </a>
      </p>
      <p>Monash Sport is here for our entire community. During this unprecedented time, 
        we are providing you with a variety of workouts, health and wellbeing tips, live 
        videos, demonstrations and motivations to support your active lifestyles.
      </p>

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/9bc42511-cfda-4e8c-9387-2ad8f4c85cba'
          target='_blank'
          rel='noopener noreferrer'>
          Ergonomic principles
        </a>
      </p>
      <p>
      Review your current workstation set-up, work practices and immediately apply 
      the feedback to the make changes as required. The course will cover How to set 
      up your workstation and where to get help.
      </p>

      <p>
        <a
          href='https://thriveglobal.com/stories/prioritizing-sleep-in-the-time-of-coronavirus/?utm_source=Newsletter_General&utm_medium=Thrive'
          target='_blank'
          rel='noopener noreferrer'>
          Why Prioritizing Sleep in the Time of Coronavirus Is So Important
        </a>
      </p>
      <p>
        Getting good sleep is one of the few proven strategies to{' '}
        <a
          href='https://www.medicalnewstoday.com/articles/324432'
          target='_blank'
          rel='noopener noreferrer'>
          {' '}
          boost immune systems
        </a>
        . There are some simple strategies to support your sleep, your
        well-being, and your immune system during these challenging times.
      </p>

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/0664e09d-156b-51e9-8d77-3ebce2c90688'
          target='_blank'
          rel='noopener noreferrer'>
          Chair Work: Yoga Fitness and Stretching at Your Desk
        </a>{' '}
        (LinkedIn Learning Module)
      </p>
      <p>
        Follow along and learn basic stretches and exercises that help improve
        your posture, strengthen your muscles and joints, increase blood flow,
        and keep you feeling strong and energetic throughout the day.
      </p>

      <p>
        <a
          href='https://www.monash.edu/ohs/health-and-wellbeing/nutritional-health/healthy-recipes'
          target='_blank'
          rel='noopener noreferrer'>
          Healthy Recipes
        </a>{' '}
        (Monash Health and Wellbeing)
      </p>
      <p>
        Monash Wellbeing have a range of healthy recipes that support wellbeing.
      </p>

      <p>
        <a
          href='https://medium.com/the-crossover-cast/7-healthy-habits-for-remote-workers-4968abfa1a8'
          target='_blank'
          rel='noopener noreferrer'>
          7 Healthy Habits for Remote Workers
        </a>{' '}
        (The Crossover Blog)
      </p>
      <p>Tips for staying fit & focused while working from home.</p>

      <h3>Financial Wellbeing</h3>

      <p>
        <a
          href='https://moneysmart.gov.au/covid-19'
          target='_blank'
          rel='noopener noreferrer'>
          Making financial decisions
        </a>{' '}
      </p>
      <p>
        During challenging times, making financial decisions can feel
        overwhelming. moneysmart.gov.au provides support and services available
        to help you.
      </p>

      <p>
        <a
          href='https://www.ymyl.com.au/coronavirus-and-the-personal-financial-crisis/'
          target='_blank'
          rel='noopener noreferrer'>
          Financial Hardship
        </a>{' '}
      </p>
      <p>
        The Coronavirus is becoming a financial crisis for many people. This seven 
        step guide provides tips on how to handle a personal financial crisis and 
        turn things around as quickly as possible. 
      </p>

      <p>
        <a
          href='https://www.ausbanking.org.au/campaigns/financial-hardship/'
          target='_blank'
          rel='noopener noreferrer'>
          Australian Banking Associtation
        </a>{' '}
      </p>
      <p>
        Australian banks have hardship teams ready to help. If you’re dealing with the 
        impact of the COVID-19 pandemic, there are practical ways your bank can help. 
        The type of assistance will depend on individual circumstances. 
      </p>

      <h3>Social Wellbeing</h3>

      <p>
        <a
          href='https://hbr.org/2020/03/what-your-coworkers-need-right-now-is-compassion'
          target='_blank'
          rel='noopener noreferrer'>
          What Your Coworkers Need Right Now Is Compassion
        </a>{' '}(Harvard Business Review Article)
      </p>
      <p>
        This is not a time to move away from kindness and caring, even if 
        our brains nudge us in that direction.
      </p>

      <p>
        <a
          href='https://theconversation.com/social-distancing-can-make-you-lonely-heres-how-to-stay-connected-when-youre-in-lockdown-133693'
          target='_blank'
          rel='noopener noreferrer'>
          Here’s how to stay connected when you’re in lockdown 
        </a>{' '}(The Conversation Blog) 
      </p>
      <p>
        Practising social distancing will result in fewer face-to-face social 
        interactions, potentially increasing the risk of loneliness. Here are 
        some tips to remain connected.
      </p>

      <h3>Workplace</h3>

      <p>
        <a
          href='https://monash.workplace.com/groups/monashwellbeing/'
          target='_blank'
          rel='noopener noreferrer'>
          Monash Wellbeing
        </a>{' '}
        (Workplace Group)
      </p>
      <p>
        Did you know Monash Wellbeing has a Workplace page that provides the
        latest information on health and well-being initiatives for staff?
      </p>
    </Page>
  )
}

export default PageStayingWell
