import React from 'react'
import Page from './Page'

const PageWorkingFromHomeAndCaregiving = () => {
  // render

  return (
    <Page title='Working from home & caregiving'>
      <blockquote>
        There are only four kinds of people in the world. Those who have been caregivers. 
        those who are currently caregivers. Those who will be caregivers, and those who 
        will need a caregiver.
        <aside>
          Rosalynn Carter
        </aside>
      </blockquote>
      <h2>Working from home and caregiving</h2>
      <p>
        In response to COVID-19, we are introducing further flexibility in our
        approach to working from home that will allow staff to self-assess how
        their work can be undertaken from a home location while also managing
        their caregiving commitments.
      </p>
      <p>
        As a parent or carer, you are best positioned to assess what adjustments can be 
        made so your work can continue under these exceptional circumstances. We understand 
        that an individualised approach is necessary depending on the nature of care, be it 
        for children, elders or those with disability. You may choose to trial different 
        approaches to determine what works for effectively for you.
      </p>
      <p>
        While this additional flexibility is now being offered to staff to work from home 
        while balancing a range of care giving responsibilities, we also recognise that 
        there will be circumstances under which staff will not be able to combine caring 
        commitments and working from home. If this applies to you, please speak to your 
        supervisor and refer to{' '}
        <a
          href='https://www.monash.edu/policy-bank/workplace-policy/leave-and-wellbeing'
          target='_blank'
          rel='noopener noreferrer'>
          Monash Leave and Wellbeing Policies
        </a>
        {' '}for related provisions that are in place to assist you to achieve this balance. 
      </p>
      <p>
        However, we have prepared a list of suggestions that you may find helpful 
        when working from home:
      </p>

      <h2>Build and maintain a schedule</h2>

      <p>
        Create a daily or weekly schedule with the requirements of your children
        or other dependants in mind. Have a clear understanding of what work
        needs to be completed and how you can achieve it. Set goals and break
        them into manageable tasks.
      </p>
      <p>
        Consider creating a schedule for children to help them maintain a
        routine and consistency. They are used to having a schedule both at
        school and childcare, which includes various activities, rest time and
        eating. You may break it down into hours, allocating time to learning,
        creative time, chore time, quiet time and free time. Involve them in the
        process so they are more engaged.
      </p>
      <p>
        <a
          href='https://drive.google.com/file/d/1q22LXhaBiTd9QGFi63zlSagjQ_dXwsCt/view'
          target='_blank'
          rel='noopener noreferrer'>
          See here for an example
        </a>
      </p>

      <h2>Work in “Sprints”</h2>
      <p>
        To deal with possible distractions when you are at home, set clear tasks
        and time limits. You can set aside time blocks to focus on a single
        task. This may be 30 minutes during which you achieve the set task.
        After that, take a short break and share a few minutes to check in with
        children or others in your care. Then you start the whole process again.
      </p>

      <h2>Conference calls</h2>
      <p>
        Plan your meetings and conference calls in advance so you can organise a
        time that suits.
      </p>
      <p>
        This does not mean removing yourself from the family setting but rather
        planning according to what you know works the best. Let your colleagues
        know if there is a possibility that you may be interrupted.
      </p>

      <h2>Adjusting work hours or schedule</h2>
      <p>
        You may wish to utilise non-standard start and finish time in order to
        maximise opportunities to work with minimal or no distractions. This
        could be starting your work before children wake up in the morning
        and/or working when they go to sleep.
      </p>
      <p>
        You can also discuss with your supervisor how your part-time hours can
        be distributed differently to better accommodate working from home with
        children or other dependants. For example, staff working 4 days a week
        (0.8 fraction) may find it more productive if working ~6 hours a day 5
        days a week.
      </p>
      <p>
        Reducing your weekly hours for a period of time can also enable you to
        better combine working from home and caregiving. It is important that
        these decisions are based on your honest self-assessment to make sure
        your family is safe and your well-being not compromised.
      </p>

      <h2>Stay in touch with colleagues</h2>
      <p>
        Staying in touch is important to provide a sense of normalcy, but also 
        so we all can benefit from the support co-workers can provide. Keeping
        connected contributes to our wellbeing and the wellbeing of our teams.
      </p>
      <p>
        You can also share ideas about what helps you keep focused and
        productive and children engaged during the day. You can do that via
        phone, email or relevant Workplace groups (e.g.{' '}
        <a
          href='https://monash.workplace.com/groups/2880807248606028/'
          target='_blank'
          rel='noopener noreferrer'>
          Parents at Monash
        </a>
        ).
      </p>
      <p>
        Make sure you advise your colleagues of any changes to your working
        hours so you can plan meetings accordingly and ensure your collaboration
        on work projects continues smoothly.
      </p>

      <h2>Talk to your supervisor</h2>
      <p>
        Speak regularly to your supervisor. Set regular phone or video
        conference meetings to discuss what you are working on, deadlines and
        expectations.
      </p>
      <p>
        Please talk to your supervisor to agree on what type of flexibility will
        help you work from home effectively. This may need to shift and evolve
        as circumstances change.
      </p>
      <p>
        Regularly discuss with your supervisor how your working from home
        arrangement is going, if your hours need to be adjusted or if you need
        other support.
      </p>

      <h2>Relevant articles</h2>
      <p>
        <a
          href='https://www.cnbc.com/2020/03/16/how-to-work-from-home-with-your-kids-during-the-coronavirus-outbreak.html'
          target='_blank'
          rel='noopener noreferrer'>
          “5 tips for effectively working from home during the coronavirus
          outbreak, when you have kids"
        </a>
      </p>
      <p>CNBC, Published 16 March 2020</p>

      <p>
        <a
          href='https://www.ctvnews.ca/health/coronavirus/help-i-ve-got-kids-what-do-i-do-tips-for-parents-working-from-home-1.4855001'
          target='_blank'
          rel='noopener noreferrer'>
          “Help! I’ve got kids, what do I do? Tips for parents working from
          home."
        </a>
      </p>
      <p>CTV News, Published 16 March 2020</p>

      <p>
        <a
          href='https://www.poynter.org/business-work/2020/how-to-work-from-home-with-kids-around/'
          target='_blank'
          rel='noopener noreferrer'>
          “How to work from home with kids around”
        </a>
      </p>
      <p>Poynter, Published 13 March 2020</p>

      <p>
        <a
          href='https://www.businessinsider.com/how-to-work-from-home-with-young-children-during-coronavirus-2020-3?r=AU&IR=T'
          target='_blank'
          rel='noopener noreferrer'>
          "5 tips to help you stay productive when working from home with young
          kids during coronavirus, from a dad who's done it"
        </a>
      </p>
      <p>Business Insider, Published 18 March 2020</p>

      <p>
        <a
          href='https://hbr.org/insight-center/coronavirus?ab=articlewidget-insightcenter-coronavirus'
          target='_blank'
          rel='noopener noreferrer'>
          "Balancing Work and Elder Care Through the Coronavirus Crisis"
        </a>
      </p>
      <p>Harvard Business Review, March 31, 2020</p>
      
      <p>
        <a
          href='https://www.forbes.com/sites/meimeifox/2020/03/25/4-educational-company-founders-share-tips-for-managing-school-and-work-from-home/?utm_source=newsletter&utm_medium=email&utm_campaign=career&cdlcid=5e718204fc818275eac12b64#56b98e4e7238'
          target='_blank'
          rel='noopener noreferrer'>
          Tips For Managing School And Work From Home
        </a>
      </p>
      <p>
        Four founders and leaders of children’s educational companies share their 
        tips on how you can survive school closures and even help your kids thrive 
        with at-home learning while you work from home.
      </p>
    </Page>
  )
}

export default PageWorkingFromHomeAndCaregiving
