import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import s from './page.module.scss'
import ContentHeader from 'components/content-header/ContentHeader'
import gTagManager from 'utils/gtag-manager.js'

const Page = ({ title, children }) => {
  // render

  const location = useLocation()

  useEffect(() => {
    console.log('Location changed: ', location)
    gTagManager.updatePagePath(
      location.pathname.replace(/'/g, '') + location.search
    )
  }, [location])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ContentHeader title={title}></ContentHeader>

      <main className={s.main}>{children}</main>
    </>
  )
}

export default Page
