import React from 'react'
import Page from './Page'

const PageBackOnCampus = () => {
  // render

  return (
    <Page title='Back on campus'>
      <blockquote>
        Life is like riding a bicycle, to keep your balance, you must keep moving
        <aside>
          Albert Einstein
        </aside>
      </blockquote>
      
      <h2>Team connection</h2>

      <p>
        <a
          href='https://www.linkedin.com/pulse/how-re-enter-office-work-setting-dave-ulrich/?trackingId=A1wTHIgLZ6w75bHiyjPnhw%3D%3D'
          target='_blank'
          rel='noopener noreferrer'>
          How to re-enter the office work setting
        </a> {' '} (Dave Ulrich Blog)
      </p>
      <p>  
        Life’s greatest lessons often result from transitions. Most of us have 
        experienced personal hardships. But the lessons learned from these difficult 
        times often create insights that we want to take with us in a transition 
        to shape a more positive future. 
      </p>

      <p>
        <a
          href='https://lens.monash.edu/2020/04/28/1380214/covid-19-the-stages-of-isolation-and-how-to-cope'
          target='_blank'
          rel='noopener noreferrer'>
          COVID-19: The Stages of isolation and how to manage workforce re-entry
        </a> {' '} (Monash Lens)
      </p>
      <p> 
        Peoples’ day-to-day lives have changed significantly as a result of the 
        pandemic – and it may be hard to transition back to “normality”. When 
        lockdown is lifted, people may struggle to get back into their routine, 
        and to return to a more productive way of life.
      </p>

      <p>
        <a
          href='https://hbr.org/2020/05/lead-your-team-into-a-post-pandemic-world'
          target='_blank'
          rel='noopener noreferrer'>
          Lead your team into a post-pandemic world
        </a> {' '} (Harvard Business Review Article)
      </p>
      <p> 
        This crisis will last more than a few short weeks, companies are now defining 
        their approach for the long haul.
      </p>

      <p>
        <a
          href='https://drive.google.com/open?id=1jR9NiZdbqvTwb5-WnGgB0ISx_vaBRa1P'
          target='_blank'
          rel='noopener noreferrer'>
          Tuning in, turning outward: Cultivating compassionate leadership in a crisis 
        </a> {' '} (McKinsey & Company Blog)
      </p>
      <p> 
        Four qualities, awareness, vulnerability, empathy, and compassion, are 
        critical for business leaders to care for people in crisis and set the 
        stage for business recovery.
      </p>

      <p>
        <a
          href='https://joshbersin.com/2020/04/covid-19-may-be-the-best-thing-that-ever-happened-to-employee-engagement/'
          target='_blank'
          rel='noopener noreferrer'>
          COVID-19 May Be The Best Thing That Ever Happened To Employee Engagement
        </a> {' '} (Josh Bersin Article)
      </p>
      <p> 
        Consider a bizarre thought. In the middle of a pandemic, our research now shows 
        that companies are treating their employees better than ever. And employee 
        engagement, a problem that plagues more than two-thirds of companies year 
        after year, is actually going up.
      </p>

      <p>
        <a
          href='http://www.harvardbusiness.org/wp-content/uploads/2020/04/HBR_Leaders_do_you_have_a_Clear_Vision_for_the_Post-Crisis_Future.pdf'
          target='_blank'
          rel='noopener noreferrer'>
          Leaders, do you have a clear vision for the post-crisis future?
        </a> {' '} (Harvard Business Review Article)
      </p>
      <p> 
        The business environment that you land in when the pandemic comes to an 
        end — which could be one to two years from now — may be very different from 
        what it was before the crisis began.
      </p>

      <h2>Let's look forward</h2>

      <p>
        <a
          href='https://www.youtube.com/watch?v=OESo4GMG8-k&feature=youtu.be'
          target='_blank'
          rel='noopener noreferrer'>
          The coronavirus crisis: what recovery looks like
        </a> {' '} (Josh Berson Video)
      </p>
      <p> 
        Our society, economy, and businesses are now starting to recover from COVID-19. 
        In this video I share the results of dozens of conversations with senior 
        business leaders and share the findings. We're going through three phases of 
        recovery, and they're all happening fast.
      </p>

      <p>
        <a
          href='https://www.mckinsey.com/industries/healthcare-systems-and-services/our-insights/beyond-coronavirus-the-path-to-the-next-normal?cid=soc-app'
          target='_blank'
          rel='noopener noreferrer'>
          Beyond coronavirus: The path to the next normal
        </a> {' '} (McKinsey & Company Blog)
      </p>
      <p> 
      The coronavirus is not only a health crisis of immense proportion—it’s also an 
      imminent restructuring of the global economic order. Here’s how leaders can begin 
      navigating to what’s next.
      </p>

      <p>
        <a
          href='https://hbr.org/2020/04/preparing-your-business-for-a-post-pandemic-world?utm_medium=email&utm_source=newsletter_monthly&utm_campaign=leadership_activesubs&utm_content=signinnudge&referral=00206&deliveryName=DM79311'
          target='_blank'
          rel='noopener noreferrer'>
          Preparing your business for a post-pandemic world
        </a> {' '} (Harvard Business Review Article)
      </p>
      <p> 
        Companies must act today if they are to bounce back in the future. Doing so 
        will help the world as a whole recover and, we hope, become more resilient in 
        the process.
      </p>

      <p>
        <a
          href='https://www.mckinsey.com/featured-insights/leadership/the-future-is-not-what-it-used-to-be-thoughts-on-the-shape-of-the-next-normal'
          target='_blank'
          rel='noopener noreferrer'>
          The future is not what it used to be: Thoughts on the shape of the next normal
        </a> {' '} (McKinsey & Company Blog)
      </p>
      <p> 
        The coronavirus crisis is a world-changing event. Here are seven elements for 
        business leaders to consider as they plan for the next normal.
      </p>

      <p>
        <a
          href='https://www.gallup.com/workplace/309620/coronavirus-change-next-normal-workplace.aspx?utm_source=linkedin&utm_medium=o_social_5203b160-815d-4cf0-bcfd-b71223c68c3b&utm_campaign=covid_19&utm_term=gallup'
          target='_blank'
          rel='noopener noreferrer'>
          How coronavirus will change the 'next normal' workplace
        </a> {' '} (GALLUP article)
      </p>
      <p> 
        Even as work and life have become completely blended for many people, more than 
        half of at-home workers say they would prefer to continue working remotely as 
        much as possible once restrictions on businesses and school closures are lifted.
      </p>

      <p>
        <a
          href='https://drive.google.com/open?id=1lHiJpxhDzdCdQOoVEozBvTupjAceQQ0j'
          target='_blank'
          rel='noopener noreferrer'>
          Return to the workplace: Move forward with confidence
        </a>{' '}(Mercer paper) 
      </p>
      <p> 
        Companies will need to think both tactically and strategically, and plan for 
        the short and long term by keeping a sharp focus on three critical actions. 
        Prepare your workplace and your employees, support your people and strengthen 
        enterprise resilience.
      </p>

      <h2>Wellbeing/Health</h2>

      <p>
        <a
          href='https://thriveglobal.com/stories/lifelong-meaningful-habits-lessons-rituals-covid-silver-linings/?utm_source=Newsletter_General&utm_medium=Thrive'
          target='_blank'
          rel='noopener noreferrer'>
          Meaningful habits and life lessons we’re learning from this time 
        </a> {' '} (Thrive Blog)
      </p>
      <p> 
        From a revised morning routine to a newfound love for at-home workouts, there 
        are some unexpected upsides we can take away from this global crisis.
      </p>

      <p>
        <a
          href='https://cpl.com/cpl-insights/insight-manage/wellness-8-atypical-approach/'
          target='_blank'
          rel='noopener noreferrer'>
          8 A-Typical employee wellness solutions for COVID-19 & beyond
        </a> {' '} (CPL Blog)
      </p>
      <p> 
        In times where existing habits are disrupted, the onus has to shift to 
        organisations to promote wellbeing but also to create a framework that 
        enables people to flourish.
      </p>

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/dad8cb9f-7528-5974-a5ce-5ef728979a0b'
          target='_blank'
          rel='noopener noreferrer'>
          How to Manage Feeling Overwhelmed
        </a> {' '}(Linkedin Learning Course)
      </p>
      <p>
        If you're feeling overwhelmed at the moment, you are certainly not alone...
        <br />
        Linkedin Learning has a great 45-minute online program - How to Manage Feeling 
        Overwhelmed where you will learn how to identify and conquer that overwhelmed 
        feeling—so you can remain focused, productive, and in control in the face of 
        whatever comes your way.
      </p>

      <p>
        <a
          href='https://apm.net.au/course2/index.html#/'
          target='_blank'
          rel='noopener noreferrer'>
          Welcome Back - Managing Mental Health During a Time of Crisis
        </a>
      </p>
      <p>
        You may be anxious about returning to work, commuting on public transport, or 
        have experienced difficult situations during the lock down. To help you 
        successfully transition back to work, it is important to be aware of your mental 
        health during this time.
      </p>

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/3fe6f6a1-aed6-41c7-ad5f-f18058ba9909?isCompletionRedirect=true&loStatus=16&regnum=1&loId=3fe6f6a1-aed6-41c7-ad5f-f18058ba9909'
          target='_blank'
          rel='noopener noreferrer'>
          Self esteem and positive thinking
        </a>
      </p>
      <p>
        Returning to work after the easing of the isolation restrictions can be a 
        stressful time. In this course, you'll learn what stress is and how to recognise 
        the sources and signals of stress. You also have the opportunity to learn useful 
        techniques for managing stress during the transition back to work.
      </p>

      <h2>Policies and procedures</h2>

      <p>
        The following policies and procedures provide details about how these 
        changes will be managed:
      </p>

      <ul>
        <li>
            <a
              href='https://www.monash.edu/ohs/news-and-health-alerts/health-alert/covid19-return-to-work'
              target='_blank'
              rel='noopener noreferrer'>
              Return to work guidelines 
            </a>
        </li>
        <li>
        <a
              href='https://www.monash.edu/__data/assets/pdf_file/0008/2149712/COVID-19-Social-Distancing.pdf/_nocache'
              target='_blank'
              rel='noopener noreferrer'>
              Physical Distancing and Alternative Work Arrangements guidelines
            </a> {' '} for staff to identify instances where they may still work from 
            home as we transition to on-campus operations. This will include staff who 
            have home schooling responsibilities and who are immuno-compromised or 
            otherwise in the higher-risk category for Covid-19 infection themselves.
        </li>
      </ul>


    </Page>
  )
}

export default PageBackOnCampus
