// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11'
import 'utils/polyfill-text-encoder'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import './index.scss'
import App from './App.jsx'
import gTagManager from 'utils/gtag-manager.js'
import * as serviceWorker from './serviceWorker'

import { AuthProvider } from './utils/AuthContext'

gTagManager.init()

let ssoHandlerOrigin = window.location.origin
if (window.location.origin.startsWith('http://localhost')) {
  ssoHandlerOrigin = 'http://localhost:8080'
}

ReactDOM.render(
  <AuthProvider loginUrl={ `${ssoHandlerOrigin}/sso/oauth2/pkce/login` }>
    <Router>
      <Route path='/' exact component={App} />
      <Route path='/topics/**' exact component={App} />
    </Router>
  </AuthProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
