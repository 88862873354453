import React from 'react'
import s from './topics.module.scss'
import listOfTopics from './list-of-topics'
import importAll from 'utils/import-all'
import { Link } from 'react-router-dom'
import recent from 'utils/is-recent'

const Topics = () => {
  const images = importAll(
    require.context('assets/icons', false, /\.(png|jpe?g|gif|svg)$/)
  )

  const list = listOfTopics.slice(1).map((item, i) => {
    const src = item.name
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/:/g, '')
      .replace(/&/g, 'and')

    return (
      <li key={i} style={{ animationDelay: i / 10 + 's' }}>
        {recent(item.updated) && <div className={s.recent}>Updated</div>}
        <Link to={`/topics/${src}`}>
          <img src={images[src]} alt={item.name}></img>
          <span>{item.name}</span>
        </Link>
      </li>
    )
  })

  // render

  return <ul className={s.topics}>{list}</ul>
}

export default Topics
