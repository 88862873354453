import React from 'react'
import s from './footer.module.scss'
import { IconGO8 } from 'components/core/icons/Icons'

const Footer = () => {
  // render

  return (
    <div>
      <footer className={s.footer}>
        <IconGO8></IconGO8>
        <p>
          Copyright © 2020 Monash University. ABN 12 377 614 012&nbsp;
          <a href="https://www.monash.edu/accessibility">Accessibility</a> - <a href="https://www.monash.edu/disclaimer-copyright">Disclaimer and copyright</a> -
          &nbsp;<a href="https://www.monash.edu/terms-and-conditions">Website terms and conditions</a> - <a href="https://www.monash.edu/privacy-monash">Data Protection and Privacy Procedure</a> - Data Consent Settings, Monash
          University CRICOS Provider Number: 00008C, Monash College CRICOS
          Provider Number: 01857J. Monash University is a registered higher
          education provider under the TEQSA Act 2011.
        </p>
      </footer>
    </div>
  )
}

export default Footer