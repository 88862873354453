import React from 'react'
import s from './main.module.scss'
import Topics from './topics/Topics'
import FurtherInformation from './further-information/FurtherInformation'

const Main = () => {
  // render

  return (
    <main className={s.main}>
      <Topics></Topics>
      <FurtherInformation></FurtherInformation>
    </main>
  )
}

export default Main
