import { gtmId } from './config'

// https://developers.google.com/analytics/devguides/collection/gtagjs
const init = (params) => {
    console.log("[GTM] GA_MEASUREMENT_ID: ", gtmId)
    if (params) console.log("[GTM] Initialising gTag: ", params)

    let importScript = document.createElement('script')
    importScript.async = true;
    importScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`
    importScript = document.head.insertAdjacentElement('beforeend', importScript)

    let initScript = document.createElement('script')
    initScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    
    gtag('config', '${gtmId}'${params?", " + JSON.stringify(params) : ""});
    `
    initScript = document.head.insertAdjacentElement('beforeend', initScript)

    return [ importScript, initScript ]
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
const updatePagePath = (path) => {
    console.log("[GTM] Updating page path.")

    let updatePagePathScript = document.createElement('script')
    updatePagePathScript.innerHTML = `
    gtag('config', '${gtmId}', {'page_path': '${path}'});
    `
    updatePagePathScript = document.body.insertAdjacentElement('beforeend', updatePagePathScript)

    return updatePagePathScript
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const sendEvent = (action, params) => {
    console.log("[GTM] Sending event: ", {action, params})

    let sendEventScript = document.createElement('script')
    
    sendEventScript.innerHTML = `
    gtag('event', '${action}'${params?", " + JSON.stringify(params) : ""});
    `
    sendEventScript = document.body.insertAdjacentElement('beforeend', sendEventScript)

    return sendEventScript
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id
const updateUserId = (userId) => {
    console.log("[GTM] Updating user id: ", userId)

    let updateUserIdScript = document.createElement('script')
    updateUserIdScript.innerHTML = `
    gtag('config', '${gtmId}', {'user_id': '${userId}'});
    `
    updateUserIdScript = document.body.insertAdjacentElement('beforeend', updateUserIdScript)

    return updateUserIdScript
}

export default { init, updatePagePath, sendEvent, updateUserId }