const listOfTopics = [
  { name: 'Getting ready', updated: 1594015771361 },
  { name: 'Back on campus', updated: 1594015771361 },
  { name: 'Setting yourself up for success', updated: 0 },
  { name: 'Digital literacy', updated: 1594015771361 },
  { name: 'Staying connected', updated: 0 },
  { name: 'Managing remote teams', updated: 1594015771361 },
  { name: 'Remaining effective & efficient', updated: 0 },
  { name: 'Staying well', updated: 0 },
  { name: 'Working from home & caregiving', updated: 0 },
  { name: 'COVID-19 updates', updated: 0 },
  { name: 'Micro learning: Hot topics', updated: 1589869501502 },
  { name: 'Key Contacts', updated: 0 },
  { name: "We'd love to hear from you", updated: 0 },
]

//time in milliseconds

export default listOfTopics
