  
import React, { useState, createContext } from 'react';
import { auth } from './fb.js'
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();

const AuthContext = createContext();

const AuthProvider = (props) => {

  const defaultAuthState = {
    loggedIn: false,
    uid: null,
    fbUser: null,
    oktaToken: null,
    firebaseToken: null,
    login: async () => {
        const loginUrl = `${props.loginUrl}?redirect_uri=${window.location}`
        const urlParams = new URLSearchParams(window.location.search);

        const oktaToken = urlParams.get('okta_token')
        const firebaseToken = urlParams.get('firebase_token')
        const uid = urlParams.get('uid')

        urlParams.delete('okta_token')
        urlParams.delete('firebase_token')
        urlParams.delete('uid')
        const replaceTo = `${window.location.pathname}`.replace(window.location.search, urlParams.toString())
        browserHistory.push(replaceTo) 

        if (uid && oktaToken && firebaseToken) {
            
            const userCredential = await auth.signInWithCustomToken(firebaseToken)
            const fbUser = userCredential.user

            if (fbUser) {
                setAuthState((state) => ({ ...state, 
                    loggedIn: true,
                    uid: uid,
                    fbUser: fbUser,
                    oktaToken: oktaToken,
                    firebaseToken: firebaseToken
                }))
                return 
            }
        }
        window.location = loginUrl 
    }
  }
  
  const [authState, setAuthState] = useState(defaultAuthState);

  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider };