import React from 'react'
import Page from './Page'

const PageMicrolearningHotTopics = () => {
  // render

  return (
    <Page title='Micro learning: Hot topics'>
      <blockquote>
        Intelligence is the ability to adapt to change.
        <aside>
          Stephen Hawking
        </aside>
      </blockquote>
      <h2>Micro learning</h2>

      <p>
        Working from home is a perfect time to consider learning opportunities.
        We've curated some micro learning on topics that you might find
        valuable:
      </p>
      <h3>Presenting with Impact</h3>
      <p>
        <a
          href='https://www.youtube.com/watch?v=IdJbJMUFzZA'
          target='_blank'
          rel='noopener noreferrer'>
          Charisma versus Stage Fright
        </a>
        {' '}(TEDTalk)
      </p>
      <p>
        Deborah Frances-White gives a masterclass on how to convert those stage
        fright nerves into a magnetic stage presence, with her characteristic
        wit, humour and of course, charisma.
      </p>

      <h3>Self Management</h3>
      <p>
        <a
          href='https://www.youtube.com/watch?v=cef35Fk7YD8'
          target='_blank'
          rel='noopener noreferrer'>
          You are Contagious
        </a>
        {' '}(TEDtalk)
      </p>
      <p>
        Our behaviour, from hand gestures to how we say 'hello', changes the way
        others perceive our confidence.
      </p>

      <h3>Team work</h3>
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/62b893bd-6c6b-5c0d-b31b-9526922c23f3'
          target='_blank'
          rel='noopener noreferrer'>
          Having Difficult Conversations
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Conflict is inevitable—in work and in life. Managers must address
        performance issues, and colleagues with competing priorities must figure
        out how to work together.
      </p>
       

      <h3>Leadership</h3>
      <p>
        <a
          href='https://www.ted.com/talks/simon_sinek_how_great_leaders_inspire_action?language=en'
          target='_blank'
          rel='noopener noreferrer'>
          How Great Leaders Inspire Action
        </a>
        {' '}(TEDtalk)
      </p>
      <p>
        Simon Sinek has a simple but powerful model for inspirational leadership
        -- starting with a golden circle and the question: "Why?" His examples
        include Apple, Martin Luther King Jr. and the Wright brothers ...
      </p>

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/4a572a52-27bc-5598-a4c6-a963213a7713'
          target='_blank'
          rel='noopener noreferrer'>
          Leading without Formal Authority
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Leadership isn't just a skill for managers. Demonstrating leadership is
        important for employees at every level of an organisation. There are
        opportunities to lead every day, whether you hold a formal leadership
        position or not.
      </p>
       

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/cdb44979-d030-5668-a45a-44c01022c2ad'
          target='_blank'
          rel='noopener noreferrer'>
          Influencing Others
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Ever had trouble persuading someone to do something, even if it was in
        their best interest? Sometimes people don't budge, John Ullmen explains
        how to influence others when you're at the "pivot point of influence" by
        applying scientifically confirmed methods.
      </p>
       

      <h3>Communication</h3>
      <p>
        <a
          href='https://www.ccl.org/articles/leading-effectively-articles/coaching-others-use-active-listening-skills/'
          target='_blank'
          rel='noopener noreferrer'>
          Use Active Listening to Coach Others
        </a>
        {' '}(Center for Creative Leadership Blog)
      </p>
      <p>
        Active listening can enrich your workplace relationships, improve group
        productivity, and even boost your own mood. While it’s inevitable for
        conflict to arise from communication, you can turn misunderstandings
        into positive action with ‘active listening'.
      </p>

      <p>
        <a
          href='https://www.ted.com/talks/celeste_headlee_10_ways_to_have_a_better_conversation'
          target='_blank'
          rel='noopener noreferrer'>
          10 Ways to Have a Better Conversation
        </a>
        {' '}(TEDtalk)
      </p>
      <p>
        When your job hinges on how well you talk to people, you learn a lot
        about how to have conversations. In this insightful talk, Celeste
        Headlee shares 10 useful rules for having better conversations.
      </p>

      <h3>Time Management</h3>
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/22d6177c-bfb3-56c3-a266-c05b0118b5a9'
          target='_blank'
          rel='noopener noreferrer'>
          Managing Your Calendar for Peak Productivity
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        You can manage your time more effectively by learning how to leverage
        the features of your calendar.
      </p>
       

      <h3>Creativity</h3>
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/cbc5492c-f126-5709-bab0-ecb5b88fe308'
          target='_blank'
          rel='noopener noreferrer'>
          Strategic Thinking
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Strategic thinking is the ability to think on a big and small scale,
        long and short term, and into the past and the present.
      </p>
      <p>
        Learn how to use strategic thinking to guide the direction of their
        teams and come up with solutions to key business problems.
      </p>
       

      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/69fbb6dc-9ae8-50f6-9bae-f00b6e448e97'
          target='_blank'
          rel='noopener noreferrer'>
          Solving Business Problems
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Learn to pin the problem down and define it, generate possible
        solutions, determine the best solution, and create a clear
        recommendation to solve your particular challenge.
      </p>
       

      <h3>Self Leadership</h3>
      <p>
        <a
          href='https://www.youtube.com/watch?v=_Ky-mKuhKgU'
          target='_blank'
          rel='noopener noreferrer'>
          Reflection for Self Growth
        </a>
        {' '}(Capture your Flag video)
      </p>
      <p>
        Simon Sinek answers "What Role Has Reflection Played in Shaping Your
        Personal Growth?" Sinek notes the importance of looking internally at
        his past actions and decisions and evaluating his performance. Sinek
        notes how the observer often learns the most.
      </p>

      <h3>Leading with Emotional Intelligence</h3>
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/9cbdbe09-039f-5e50-b9e2-6f721745de7d'
          target='_blank'
          rel='noopener noreferrer'>
          Leading with Emotional Intelligence
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Emotions are all around us in the office, and it's important for
        Managers and Leaders to understand how to harness them to cultivate
        productivity and positive relationships. In this course, you will learn
        how to boost your emotional quotient (EQ) to better lead teams, work
        with peers, and manage up. Learn what emotional intelligence is and how
        it factors in at work, and discover concrete techniques for raising your
        own EQ.
      </p>

      <h3>Finance</h3>

      <p>
        <a
          href='https://www.eventbrite.com.au/e/money-talks-registration-105324580656'
          target='_blank'
          rel='noopener noreferrer'>
          Money Talk for Women - A financial education practical online series
        </a>
      </p>
      <p>
        Women’s Health East has partnered with Financial Experts Money Natters to 
        present an informative and practical online series focusing on:
        <ul>
          <li>Budgeting & saving</li>
          <li>Financial pressure & getting help</li>
          <li>Women creating wealth - Investing & starting a business</li>
          <li>Superannuation</li>
        </ul>
        These lunchtime sessions will be held throughout the month of June.
      </p>
    </Page>
  )
}

export default PageMicrolearningHotTopics
