import React from 'react'
import Page from './Page'

const PageCovid19Updates = () => {
  // render

  return (
    <Page title='COVID-19 updates'>
      <h2>
        <a
          href='https://www.monash.edu/news/coronavirus-updates'
          target='_blank'
          rel='noopener noreferrer'>
          COVID-19 (novel coronavirus)
        </a>
      </h2>
      <p>
        Monash University is closely monitoring developments surrounding{' '}
        <a
          href='https://www.monash.edu/news/coronavirus-updates'
          target='_blank'
          rel='noopener noreferrer'>
          COVID-19
        </a>{' '}
        and is committed to keeping the Monash community informed as the
        situation continues to evolve.{' '}
      </p>
      <p>
        See the Monash{' '}
        <a
          href='https://www.monash.edu/news/coronavirus-updates'
          target='_blank'
          rel='noopener noreferrer'>
          website
        </a>{' '}
        to stay up-to-date with latest advice and information.
      </p>

      <h2>Do you think you have COVID-19?</h2>

      <p>
        <a
          href='https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert'
          target='_blank'
          rel='noopener noreferrer'>
          Coronavirus (COVID-19) health alert
        </a>{' '}from the Department of Health.
      </p>
      <p>
        Stay at home if you feel unwell. If you have a 
        fever, cough and difficulty breathing, seek medical attention and call in advance.
      </p> 


      <h2>Does your staff member have or may have COVID-19? </h2>

      <p>
        <a
          href='https://www.safeworkaustralia.gov.au/covid-19-information-workplaces/what-do-if-worker-has-covid-19'
          target='_blank'
          rel='noopener noreferrer'>
          What to do if a worker has COVID-19
        </a>{' '}(Safework Australia)
      </p>
      <p>
        See their advice on this infographic 
      </p>

      <p>
        <a
          href='https://hbr.org/2020/03/your-employee-tested-positive-for-covid-19-what-do-you-do'
          target='_blank'
          rel='noopener noreferrer'>
          Your Employee Tested Positive for Covid-19. What Do You Do?
        </a>{' '}(Harvard Business Review Article)
      </p>
      <p>
        Express sympathy. Connect with your HR partner. Provide support. 
      </p>

      <h2>Does your colleague have COVID-19?</h2>

      <p>
        <a
          href='https://hbr.org/2020/03/what-your-coworkers-need-right-now-is-compassion'
          target='_blank'
          rel='noopener noreferrer'>
          What Your Coworkers Need Right Now Is Compassion
        </a>{' '}(Harvard Business Review Article)
      </p>
      <p>
        Remember this is an opportunity for connection. Be generous in 
        your interpretations. Acknowledge how you’re feeling. 
      </p>
      
    </Page>
  )
}

export default PageCovid19Updates
