import React from 'react'
import s from './banner.module.scss'

const Banner = () => {
  // render

  return (
    <div className={s.banner}>
      <h1>Welcome to monash @ Home</h1>
      <p>
        You may not have expected to find yourself temporarily working from home
        (WFH) this year, neither did we!
      </p>
      <p>
        The following web page has been developed with information / learning /
        resources to assist and support you while working from home.
      </p>
    </div>
  )
}

export default Banner
