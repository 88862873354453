import React from 'react'
import Page from './Page'

const PageGettingReady = () => {
  // render

  return (
    <Page title='Getting ready'>
      <blockquote>
        Before anything else, preparation is the key to success.
        <aside>
          Alexander Graham Bell
        </aside>
      </blockquote>
      
      <h2>Preparing to work from home</h2>

      <ul>
        <li>
          Do you have a laptop and access to the internet? 
          <br />
          (if not talk with your supervisor to discuss alternative arrangements)
        </li>
        <li>
          What work resources/documents will you need to take home
          <br />
          (confidential doc's should not go home)
        </li>
        <li>Check that you can login remotely from home</li>
        <li>
          Check you can{' '}
          <a
            href='https://www.monash.edu/esolutions/data-storage/how-to-map-s-drive'
            target='_blank'
            rel='noopener noreferrer'>
            access your S drive
          </a>
          ? or ensure or upload essential documents to your Google Drive
        </li>
        <li>
          Check you can{' '}
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-drive'
            target='_blank'
            rel='noopener noreferrer'>
            access Google drive
          </a>
          ? (Our preferred system)
        </li>
        <li>
          Do you know how to{' '}
          <a
            href='https://www.monash.edu/esolutions/learning-meeting-spaces/zoom-conferencing'
            target='_blank'
            rel='noopener noreferrer'>
            use Zoom for meetings
          </a>
          ?
        </li>
        <li>
          Do you know how to{' '}
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-hangouts'
            target='_blank'
            rel='noopener noreferrer'>
            use Google 'hangouts' to connect
          </a>
          {' '}with colleagues?
        </li>
      </ul>
      <p>
        Staff with Disability who have a workplace adjustment in place should talk 
        to their supervisor about adjustments that may be needed in a home working 
        environment. Alternatively, you may wish to contact your HRBP to discuss 
        options.
      </p>

      <h2>
        What technology do you need to work from home?
      </h2>
      <p>
        Staff who are approved a temporary working from home arrangement in
        response to COVID-19 are to use their existing laptop to work from home.
      </p>
      <p>
        In the event that they do not have a laptop, staff can{' '}
        <a
            href='https://servicedeskonline-myit.onbmc.com/dwp/rest/share/OJSXG33VOJRWKSLEHVJVER2BIE2VMMCHG42ESV2BJ5IUES2CKJHVARSMLFHTOMCWIETHEZLTN52XEY3FKR4XAZJ5KNJEIJTUMVXGC3TUJFSD2MBQGAYDAMBQGAYDAMBQGE3TQJTDN5XHIZLYORKHS4DFHVBUCVCBJRHUOX2IJ5GUKJTQOJXXM2LEMVZFG33VOJRWKTTBNVST243SNU======'
            target='_blank'
            rel='noopener noreferrer'>
            request a temporary laptop kit
          </a>
         {' '}to be loaned for the duration of the Working from Home Period.
      </p>
      <p>
        <a
          href='https://docs.google.com/document/d/1PzAEOM3Yw86XMysgAPXEEaUKoFJ485M8tKA9xrj7eYc/edit?ts=5e719248#'
          target='_blank'
          rel='noopener noreferrer'>
          Do you know about Monash VPN network?
        </a>
      </p>
      <p>
        This allows you access to certain Monash services that require 
        additional security. You should only use the VPN if the specific service 
        you would like to access requires it. The VPN software is already installed 
        onto Monash-provided laptops.
      </p>
      <p>
        Remember to take regular breaks from the computer, get up and walk
        around and also practice these{' '}
        <a
          href='https://www.monash.edu/__data/assets/pdf_file/0010/395938/ergo-office-workers.pdf'
          target='_blank'
          rel='noopener noreferrer'>
          helpful desk exercises
        </a>
        .
      </p>

      <h2>How to's</h2>
      <p>
        Has your team discussed your preferred way of communicating and
        connecting when working remotely?
      </p>
      <p>
        See page 3 of the{' '}
        <a
          href='https://www.monash.edu/__data/assets/pdf_file/0008/2149712/COVID-19-Social-Distancing.pdf'
          target='_blank'
          rel='noopener noreferrer'>
          Social Distancing, Alternative Work Arrangements and Working from Home
        </a>
        {' '}document for how to's on
      </p>
      <ul>
        <li>Setting clear expectations</li>
        <li>Communication</li>
        <li>Access equipment & systems from home</li>
      </ul>
      <br />
      <p>Here's a useful article from the Harvard Business Review:</p>
      <p>
        <a
          href='https://www.harvardbusiness.org/supporting-teams-in-our-current-environment/'
          target='_blank'
          rel='noopener noreferrer'>
          "Supporting teams in our current environement"
        </a>
      </p>
    </Page>
  )
}

export default PageGettingReady
