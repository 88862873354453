import React from 'react'
import Page from './Page'

const PageStayingConnected = () => {
  // render

  return (
    <Page title='Staying connected'>
      <blockquote>
        Alone we can do so little, together we can do so much.
        <aside>
          Helen Keller
        </aside>
      </blockquote>
      <h2>
        Do you use{' '}
        <a
          href='https://monash.workplace.com'
          target='_blank'
          rel='noopener noreferrer'>
          {' '}
          Workplace
        </a>
        ?
      </h2>

      <blockquote>
        "Because businesses work better when everyone is on the same page."
      </blockquote>

      <p>
        <a
          href='https://monash.workplace.com'
          target='_blank'
          rel='noopener noreferrer'>
          Workplace
        </a>
        {' '} is a great place to connect with your colleagues and keep up to date on
        developments across the University.
      </p>
      <p>
        Have you got questions about using Workplace -{' '}
        <a
          href='https://monash.workplace.com/groups/monashworkplacehelp/'
          target='_blank'
          rel='noopener noreferrer'>
          Ask the Workplace Group
        </a>
      </p>

      <h2>Remember inter-office banter?</h2>

      <p>Some tips for keeping it light and ideas for daily sharing:</p>
      <ul>
        <li>How many coffees you've had for the day</li>
        <li>
          What's for lunch - take photos and post (do it for the 'gram'! -
          that's Instagram)
        </li>
        <li>
          Share a pic of something in your house that is representative of you
        </li>
        <li>
          Share a pic of who's working with you today. ie: small human or small
          animal
        </li>
        <li>
          Take a picture out the nearest window from your working space to share
        </li>
        <li>Share your music playlist with your colleagues</li>
        <li>What are you watching/reading right now?</li>
        <li>
          Ask your coworkers to share photos of their work spaces, so you can
          inspire one another
        </li>
      </ul>
      <p>
        Team members can post when they feel grateful for something that
        happened during the week (work or personal) or want to call out
        someone's hard work.
      </p>

      <p>
        <a
          href='https://hbr.org/2020/03/how-to-get-people-to-actually-participate-in-virtual-meetings'
          target='_blank'
          rel='noopener noreferrer'>
          {' '}
          How to Get People to Actually Participate in Virtual Meetings
        </a>
        {' '} (Harvard Business Review Article)
      </p>

      <h2>Connecting with Colleagues</h2>
      <ul>
        <li>Create virtual coffee catch ups over a conference call</li>
        <li>Schedule virtual lunches with the team</li>
        <li>Keep your phone close and remove 'silent mode'</li>
        <li>Embrace the webcam!</li>
        <li>
          Use google chat -{' '}
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-hangouts'
            target='_blank'
            rel='noopener noreferrer'>
            hangouts
          </a>
        </li>
        <li>
          Kick off each morning with a 15-minute standup meeting. The focus can
          just be saying hello, or you can invite people to share what they plan
          to work on for the day.
        </li>
        <li>Create a team chat group on your phone with colleagues</li>
        <li>Need some conversation inspiration? See <a
            href='https://docs.google.com/document/d/1uFVv0_RcpxRYfvvDGS9qzRbikqpUwGkSLiYxwoLN2qU/edit?usp=sharing'
            target='_blank'
            rel='noopener noreferrer'>
            here
          </a> for a list of question to ask each other</li>
      </ul>

      <h2>Schedule in time to Connect</h2>
      <ul>
        <li>Agree with your Supervisor on your preferred contact method</li>
        <li>
          Schedule weekly meetings with your Manager and colleagues - rotate
          chairs
        </li>
        <li>Remember to keep your phone switched on & the volume up</li>
      </ul>

      {/*<h2>
        A{' '}
        <a
          href='https://hbr.org/2020/03/a-guide-to-managing-your-newly-remote-workers'
          target='_blank'
          rel='noopener noreferrer'>
          Guide to Managing
        </a>
        {' '}Your (Newly) Remote Workers (For Managers)
      </h2>
      <p>
        Leaders need to adapt to the new normal, this article helps identify the
        common challenges of remote work and how managers can support remote
        employees.
      </p>
      <p>
        (
        <a
          href='https://hbr.org/2020/03/a-guide-to-managing-your-newly-remote-workers'
          target='_blank'
          rel='noopener noreferrer'>
          Harvard Business Review Article
        </a>
        )
      </p>*/}

      <p>
        <a
          href='https://hbr.org/2020/03/a-guide-to-managing-your-newly-remote-workers'
          target='_blank'
          rel='noopener noreferrer'>
          A Guide to Managing Your (Newly) Remote Workers (For Managers)
        </a>
        {' '}(Harvard Business Review Article)
      </p>
      <p>
        Leaders need to adapt to the new normal, this article helps identify the
        common challenges of remote work and how managers can support remote
        employees.
      </p>

      <h2>Random Acts of Kindness</h2>

      <blockquote>
        "Kindness is a gift everyone can afford" <aside>Unknown</aside>
      </blockquote>

      <p>
        Want to stay connected? Why not provide a random act of kindness for on
        of your colleagues...
      </p>
      <ul>
        <li>Give a team member a shout out in a team meeting</li>
        <li>Say “thank you”</li>
        <li>Ask someone how they are and really listen to the answer</li>
        <li>Be a cheerleader for someone else’s idea or project</li>
        <li>Tell your manager what you appreciate about them</li>
        <li>
          Invite a co-worker you don’t normally socialize with to a virtual
          lunch
        </li>
        <li>Share your expertise</li>
        <li>Start and end meetings on time</li>
      </ul>
    </Page>
  )
}

export default PageStayingConnected
