import React from 'react'
import s from './header.module.scss'
import Nav from 'components/header/nav/Nav'
import Banner from './banner/Banner'

const Header = () => {
  // render

  return (
    <header className={s.header}>
      <Nav></Nav>
      <Banner></Banner>
    </header>
  )
}

export default Header
