import React from 'react'
import Page from './Page'

const PageDigitalLiteracy = () => {
  // render

  return (
    <Page title='Digital literacy'>
      <blockquote>
        Knowing is not enough; We must apply. Willing is not enough; We must do.
        <aside>
          Bruce Lee
        </aside>
      </blockquote>

      <h2>Accessing IT systems</h2>

      <p>
        <strong>Access IT systems and popular tools remotely.</strong> Learn 
        how using this{' '}<a
            href='https://www.monash.edu/__data/assets/pdf_file/0005/2173559/staff-quick-reference-guide.pdf'
            target='_blank'
            rel='noopener noreferrer'>
            Quick Reference Guide
          </a>
      </p>

      <p>Get tips on:</p>
      <ul>
        <li>When to use VPN</li>
        <li>How to install free software remotely on your personal / Monash device</li>
        <li>How to remotely connect to S:Drive</li>
        <li>How to improve your home internet connection</li>
        <li>How to tether your Monash mobile to connect to the internet to reduce personal costs</li>
        <li><i>…and much more!</i></li>
      </ul>

      <p>For detailed steps on how to access systems remotely (with screenshots), check out the{' '}
          <a
            href='https://www.monash.edu/esolutions/media/documents/systems/staff-remote-access-guide.pdf'
            target='_blank'
            rel='noopener noreferrer'>
            Staff Remote Access Guide
          </a>
        .
      </p>

      <p><strong>Still need technical help?</strong></p>

      <p>Check out the{' '}<a
            href='https://www.monash.edu/esolutions'
            target='_blank'
            rel='noopener noreferrer'>
            IT Self Help site
          </a>
        .
      </p>


      <h2>Popular tools:</h2>
      <ul>
        <li>
          Can you{' '}
          <a
            href='https://www.monash.edu/esolutions/data-storage/how-to-map-s-drive'
            target='_blank'
            rel='noopener noreferrer'>
            {' '}
            access S drive
          </a>
          ?
        </li>
        <li>
          Can you{' '}
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-drive'
            target='_blank'
            rel='noopener noreferrer'>
            {' '}
            access google drive
          </a>
          ?
        </li>
        <li>
          Do you{' '}
          <a
            href='https://www.monash.edu/esolutions/learning-meeting-spaces/zoom-conferencing'
            target='_blank'
            rel='noopener noreferrer'>
            {' '}
            Zoom
          </a>
          ?
        </li>
        <li>
          Do you '
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-hangouts'
            target='_blank'
            rel='noopener noreferrer'>
            hangout
          </a>
          '?
        </li>
        <li>
          <a
            href='https://www.monash.edu/esolutions/software/microsoft-office-365'
            target='_blank'
            rel='noopener noreferrer'>
            Microsoft Office 365{' '}
          </a>
          - A program for free-form information gathering and multi-user
          collaboration.
        </li>
        <li>
          Do you need specialised software &/or applications? Try{' '}
          <a
            href='https://docs.google.com/document/d/1PzAEOM3Yw86XMysgAPXEEaUKoFJ485M8tKA9xrj7eYc/edit?ts=5e719248#heading=h.9eft50ywws6r'
            target='_blank'
            rel='noopener noreferrer'>
            MoVE
          </a>
        </li>
      </ul>

      <h2>The G Suite</h2>

      <p>
        <a
          href='https://www.monash.edu/esolutions/email-collaboration/google-apps'
          target='_blank'
          rel='noopener noreferrer'>
          Google Apps | G Suite
        </a>
      </p>
      <p>Are you making the most of these apps to say connected?</p>
      <ul>
        <li>
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/contacts'
            target='_blank'
            rel='noopener noreferrer'>
            Contacts and Groups
          </a>
        </li>
        <li>
          G{' '}
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-drive'
            target='_blank'
            rel='noopener noreferrer'>
            Drive
          </a>
          {' '}(that's Google if you missed it)
        </li>
        <li>
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-docs-sheets-slides-forms'
            target='_blank'
            rel='noopener noreferrer'>
            Docs, Sheets, Slides and Forms
          </a>
        </li>
        <li>
          <a
            href='https://www.monash.edu/esolutions/email-collaboration/google-hangouts'
            target='_blank'
            rel='noopener noreferrer'>
            Hangouts
          </a>
        </li>
      </ul>

      <p>
        Have you got questions about Google Apps -{' '}
        <a
          href='https://monash.workplace.com/groups/503747573455737/'
          target='_blank'
          rel='noopener noreferrer'>
          Ask the Workplace Group
        </a>
      </p>

      <h2>Workplace</h2>
      <p>
        Do you use{' '}
        <a
          href='https://monash.workplace.com'
          target='_blank'
          rel='noopener noreferrer'>
          Workplace
        </a>
        ?
      </p>

      <blockquote>
        "Because businesses work better when everyone is on the same page."
      </blockquote>

      <p>Workplace is a great place to connect with your colleagues.</p>
      <p>
        Have you got questions about Workplace -{' '}
        <a
          href='https://monash.workplace.com/groups/monashworkplacehelp/'
          target='_blank'
          rel='noopener noreferrer'>
          Ask the Workplace Group
        </a>
      </p>
    </Page>
  )
}

export default PageDigitalLiteracy
