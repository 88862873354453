import React from 'react'
import Page from './Page'

const PageSettingYourselfUpForSuccess = () => {
  // render

  return (
    <Page title='Setting yourself up for success'>
      <blockquote>
        Plan your work and work your plan.
        <aside>
          Napoleon Hill
        </aside>
      </blockquote>
      <h2>Working from Home</h2>

      <p>
        <a
          href='https://www.monash.edu/__data/assets/pdf_file/0008/2149712/COVID-19-Social-Distancing.pdf'
          target='_blank'
          rel='noopener noreferrer'>
          {' '}
          COVID–19 Social Distancing, Alternative Work Arrangements and Working
          from Home
        </a>{' '}
        provides guidelines for{' '}
        <a
          href='https://www.monash.edu/__data/assets/pdf_file/0008/2149712/COVID-19-Social-Distancing.pdf'
          target='_blank'
          rel='noopener noreferrer'>
          {' '}
          Staff Working from Home
        </a>{' '}
        (see page 4)
      </p>

      <p>Topics include:</p>
      <ul>
        <li>Communication</li>
        <li>Productivity</li>
        <li>Staying connected</li>
      </ul>
      <br />
      <p>
        <a
          href='https://www.google.com/url?q=https%3A%2F%2Fmonash.csod.com%2Fui%2Flms-learning-details%2Fapp%2Fcourse%2F3a7186a8-e442-5127-bbbd-634bff0cb4a9&sa=D&sntz=1&usg=AFQjCNGzrE-A_YFpWHbJJkQ5ToVUwWdp2Q'
          target='_blank'
          rel='noopener noreferrer'>
          Working Remotely
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Now you're working from home these micro learning resources on remote
        work productivity, communication and collaboration provide great
        information.
      </p>
      <br />
      <p>To assist you in WFH, key suggestions include:</p>
      <ul>
        <li>
          Develop a routine and structure to assist in planning your day (like a
          normal work day!)
        </li>
        <li>Give yourself a timeline for starting and finishing work</li>
        <li>Take a lunch break away from your desk</li>
        <li>
          Change into your work clothes from home clothes (weird - we know!)
        </li>
      </ul>

      <p>
        <a
          href='https://hbr.org/video/6140518023001/how-to-actually-workwhen-youre-working-from-home'
          target='_blank'
          rel='noopener noreferrer'>
          How to Actually Work...When You’re Working from Home
        </a>
        {' '}(Harvard Business Review Video)
      </p>
      <p>
        Because boundaries are fuzzy, you could either burn out or not get
        anything done.
      </p>

      <h2>Are you a manager?</h2>
      <p>
        For more details on how to manage your team remotely see the <a href='/topics/managing-remote-teams'>managing remote teams page</a>
      </p>
    </Page>
  )
}

export default PageSettingYourselfUpForSuccess
