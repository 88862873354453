import React from 'react'
import Page from './Page'

const PageRemainingEffectiveAndEfficient = () => {
  // render

  return (
    <Page title='Remaining effective & efficient'>
      <blockquote>
        Successful people know that small things done repeatedly have great power.
        <aside>
          Laura Vanderkam
        </aside>
      </blockquote>
      <h2>Catching up on your 'To Do' list</h2>
      <ul>
        <li>
          Have you completed your{' '}
          <a
            href='https://sites.google.com/monash.edu/homework/homework/remaining-effective-efficient/mandatory-compliance?authuser=0'
            target='_blank'
            rel='noopener noreferrer'>
            Mandatory Compliance
          </a>{' '}
          training?
        </li>
        <li>
          Have you completed your{' '}
          <a
            href='https://monashportal.pageuppeople.com/Dock.aspx'
            target='_blank'
            rel='noopener noreferrer'>
            myPlan
          </a>{' '}
          for 2020?
        </li>
        <li>What is in your myPlan that can be actioned?</li>
        <li>
          What skills would you like to learn? We have access to a great range
          of micro learning modules, available via{' '}
          <a
            href='https://monash.csod.com/LMS/catalog/Welcome.aspx?tab_page_id=-67&tab_id=-1'
            target='_blank'
            rel='noopener noreferrer'>
            myDevelopment
          </a>
        </li>
      </ul>
      <br />
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/6371a79a-ee82-55cc-bd18-dfc1e65a99b5'
          target='_blank'
          rel='noopener noreferrer'>
          Getting Things Done
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Join world-renowned productivity and time management expert David Allen
        as he walks you through his five-step process for Getting Things Done
      </p>

      <h2>Opportunities - One day I'll get to that...</h2>
      <p>Work on that wish list:</p>
      <ul>
        <li>File and organise your inbox</li>
        <li>Create a filing system for documents and delete unused files</li>
        <li>
          Create a share document (via Google) with your team for documenting
          creative ideas -{' '}
          <a
            href='https://docs.google.com/document/d/1bkGCTIsNiMjXXFR-tUsY1zD8-GbF1PMBC3P8C2apsLY/edit'
            target='_blank'
            rel='noopener noreferrer'>
            Here is an example
          </a>
        </li>
        <li>
          Do some best practice benchmarking or research to support your work
        </li>
        <li>Review and update the content on your webpage</li>
      </ul>
      <br />
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/f4485290-b206-5722-a0fb-3c7c0780c237'
          target='_blank'
          rel='noopener noreferrer'>
          Productivity Tips: Finding Your Productive Mindset (2020)
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        If you want to boost your productivity in a lasting way, you need to
        change the way you look at your time and your tasks.
      </p>
      <br />
      <p>
        <a
          href='https://monash.csod.com/ui/lms-learning-details/app/course/dc745f04-9bc3-5dc9-8618-f4e0bf3acf33'
          target='_blank'
          rel='noopener noreferrer'>
          Time Management: Working from Home
        </a>
        {' '}(LinkedIn Learning Module)
      </p>
      <p>
        Get time management tips to stay productive and balanced when working
        from home part-time or full-time.
      </p>
      <br />
      <p>
        <a
          href='https://hbr.org/2020/03/anxiety-is-contagious-heres-how-to-contain-it'
          target='_blank'
          rel='noopener noreferrer'>
          Anxiety Is Contagious. Here’s How to Contain It.
        </a>
        {' '}(Harvard Business Review article)
      </p>
      <p>
        "Meditation seemed like the perfect Purell for my students’ minds that
        morning." says Judson Brewer and it's especially important in times of
        mass uncertainty.
      </p>
    </Page>
  )
}

export default PageRemainingEffectiveAndEfficient
